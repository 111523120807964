import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { useMemo } from 'react';

interface ILink extends NextLinkProps {
  noDecoration?: boolean;
  disabled?: boolean;
  target?: string;
}

const Link: React.FC<ILink> = ({
  href,
  children,
  noDecoration,
  disabled,
  target,
  ...props
}) => {
  const styles = useMemo(() => {
    const attrs: any = {};

    if (noDecoration) attrs.textDecoration = 'none';
    if (disabled) attrs.pointerEvents = 'none';

    return attrs;
  }, [noDecoration, disabled]);

  return (
    <NextLink href={href}>
      <a style={styles} target={target} {...props}>
        {children}
      </a>
    </NextLink>
  );
};

export default Link;
