import { IEventAttendeeResponse, IEvent } from '@models/event.interface';
import api from '../api';

export const getEventAttendee = async (
  userId: string,
): Promise<Array<{ event: IEvent }>> => {
  const resp = await api.get<IEventAttendeeResponse>(
    `/event-attendee?filter[attendee]=${userId}`,
  );

  return resp.data.rows;
};
