import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@components/core/Backdrop';
import { useAuth } from '@hooks/auth';

import { useUser } from '@hooks/user';
import { Iframe, Dialog, DialogContent } from './style';

const Completed: React.FC = () => {
  const { user, setUser } = useUser();
  const [isLoadingIframe, setIsLoadingIframe] = useState(true);
  const { showModalCompleted, setCompletedModal } = useAuth();

  const completedURL = `${process.env.NEXT_PUBLIC_AUTH_CLIENT}frame/registersocial`;

  useEffect(() => {
    const onClose = message => {
      if (typeof message.data !== 'string' && !(message.data instanceof String))
        return;

      const data = JSON.parse(message.data);
      if (data.action !== 'closeCompleted') return;
      setUser({ ...user, ...data.user });
      setCompletedModal(false);
    };

    window.addEventListener('message', onClose);
    return () => {
      window.removeEventListener('message', onClose);
    };
  }, [setCompletedModal, setUser, user]);

  return (
    <Dialog
      open={showModalCompleted}
      aria-labelledby="login"
      BackdropComponent={Backdrop}
    >
      <DialogContent dividers style={{ WebkitOverflowScrolling: 'touch' }}>
        {isLoadingIframe && (
          <CircularProgress style={{ position: 'absolute' }} />
        )}
        <Iframe
          src={completedURL}
          title="Startse login modal"
          width="100%"
          height="100%"
          frameBorder="0"
          scrolling="yes"
          onLoad={() => setIsLoadingIframe(false)}
          style={{ opacity: isLoadingIframe ? '0' : '1' }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default Completed;
