import { useMemo } from 'react';

import { FlagOutlined } from '@material-ui/icons';

import Header from './Header';
import Share from './Share';
import Challenges from './Challenges';

import { Container, Title, ContainerHeader } from './styles';

import Crown from '../../../assets/svg/crown.svg';
import Diamond from '../../../assets/svg/diamond.svg';
import Medal from '../../../assets/svg/medal.svg';
import Potential from '../../../assets/svg/potential.svg';

interface IndicationsProps {
  linkToShare: string;
  totalIndications: number;
  pageName?: string;
  prefixPageName?: string;
}

export interface ILevel {
  minIndications: number;
  icon: JSX.Element;
  title: string;
  subTitle: string;
  description: string;
}

const Indications: React.FC<IndicationsProps> = ({
  linkToShare,
  totalIndications,
  pageName,
  prefixPageName,
}) => {
  const levels: ILevel[] = useMemo(
    () => [
      {
        minIndications: 2,
        icon: <FlagOutlined />,
        title: '2 AMIGOS INDICADOS',
        subTitle: 'Reports Liberados Gratuitamente:',
        description:
          'Reports e estudos sobre negócios e tendências da StartSe.',
      },
      {
        minIndications: 5,
        icon: <Medal />,
        title: '5 AMIGOS INDICADOS',
        subTitle: 'Vale do Silício:',
        description:
          'Ao indicar 5 amigos você concorrerá automaticamente a uma viagem para o Vale do Silício com tudo pago.',
      },
      {
        minIndications: 10,
        icon: <Crown />,
        title: '10 AMIGOS INDICADOS',
        subTitle: 'Acesso ao Curso Online Aprenda a Vender Pela Internet:',
        description:
          'Um dos cursos mais conceituados da StartSe, onde você aprenderá as melhores ferramentas disponíveis para fazer campanhas de marketing digital.',
      },
      {
        minIndications: 30,
        icon: <Potential />,
        title: '30 AMIGOS INDICADOS',
        subTitle: 'Acesso ao Curso Online Motor do Crescimento:',
        description:
          'Nosso Best Seller, onde você aprenderá as melhores ferramentas disponíveis para ter uma empresa bem sucedida.',
      },
      {
        minIndications: 50,
        icon: <Diamond />,
        title: '50 AMIGOS INDICADOS',
        subTitle: 'Acesso ao Curso Online Liderança Exponencial:',
        description:
          'Um dos grandes programas mais requisitados da StartSe, onde você aprenderá como ser um Líder Exponencial.',
      },
    ],
    [],
  );

  return (
    <Container>
      {pageName ? (
        <>
          <Title>
            {`Compartilhe ${prefixPageName}`}
            <span>{pageName}</span> com os seus amigos
          </Title>
          <Title>
            E concorra a uma viagem para o Vale do Silício com
            <span> TUDO PAGO!</span>
          </Title>
        </>
      ) : (
        <>
          <Title>
            Sua Inscrição <span>FOI CONFIRMADA!</span>
          </Title>
          <Title>
            Agora concorra a uma viagem para o Vale do Silício com
            <span> TUDO PAGO!</span>
          </Title>
        </>
      )}
      <ContainerHeader>
        <Header totalIndications={totalIndications} levels={levels} />
        <Share linkToShare={linkToShare} />
      </ContainerHeader>
      <Challenges levels={levels} totalIndications={totalIndications} />
    </Container>
  );
};

export default Indications;
