import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://9a82fd73d72a44dba83f60f3f8044a84@o294874.ingest.sentry.io/5905992',

  tracesSampleRate: 1.0,

  environment: process.env.NODE_ENV,
});
